$(document).ready(function() {

    // Scroll to top

    // Check to see if the window is top if not then display button
    $(window).scroll(function(){
        if ($(this).scrollTop() > 100) {
            $('#totop').fadeIn();
        } else {
            $('#totop').fadeOut();
        }
    });

    // Click event to scroll to top
    $('#totop').click(function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop : 0}, 200);
        return false;
    });

});