(function(document) {
    var shareButtons = document.querySelectorAll(".st-custom-button[data-network]");
    for(var i = 0; i < shareButtons.length; i++) {
        var shareButton = shareButtons[i];

        shareButton.addEventListener("click", function(e) {
            e.preventDefault();
            var elm = e.target;
            var network = elm.dataset.network;
            var title = elm.dataset.title;
            var image = elm.dataset.image;
            var description = elm.dataset.description;
        });
    }
})(document);