$(document).ready(function() {

    // subnav hover
    var dropdownToggle = $('.dropdown-toggle'),
        dropdownMenu = $('.dropdown-menu');

    dropdownToggle.on('click touchstart', function() {
        if ($(this).attr('data-target') !== '#nav-mobile') {
            if ($(this).hasClass('open')) {
                $(this).removeClass('open');
                $($(this).attr('data-target')).removeClass('open-subnav');
            } else {
                dropdownToggle.removeClass('open');
                dropdownMenu.removeClass('open-subnav');
                $(this).addClass('open');
                $($(this).attr('data-target')).addClass('open-subnav');
            }
        }
    });

    // sticky navbar shrink
    // $(window).scroll(function() {
    //     const navbar = $("#navbar"),
    //         navbarRowTopSecond = $('.navbar-row-top-second'),
    //         navbarRowTopClone = $('.navbar-row-top-clone');
    //
    //     if ($(document).scrollTop() > 50) {
    //         navbar.addClass('navbar-shrink');
    //         navbarRowTopSecond.css('height',0).css('padding',0);
    //         navbarRowTopClone.addClass('d-inline-block').fadeIn('slow');
    //     } else {
    //         navbar.removeClass('navbar-shrink');
    //         navbarRowTopClone.fadeOut('slow').removeClass('d-inline-block');
    //         navbarRowTopSecond.css('height',50).css('padding-top',5).css('padding-bottom',5);
    //     }
    // });

});